import {
  SET_SUBJECTS,
  LOADING_SUBJECTS,
  SET_FILTERED_SUBJECTS,
  LOADING_FILTERED_SUBJECTS,
} from "../constants";

const initialState = {
  data: [],
  isLoading: false,
};

export const subjectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_SUBJECTS: {
      return { ...state, isLoading: true };
    }
    case SET_SUBJECTS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const filteredSubjectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_FILTERED_SUBJECTS: {
      return { ...state, isLoading: true };
    }
    case SET_FILTERED_SUBJECTS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};
