import {getDateTime, getFileName} from "../../service/geoJsonData";
import {getData, getZippedData} from "../../pages/map/utils";
import {SET_TIME_GEO_JSON} from "../constants";

export const setGeoData = (data) => ({
    type: SET_TIME_GEO_JSON,
    payload: data,
});


const findYearInObject = (object, date) => {
    return object.hasOwnProperty(date)
}


export const getTimeGeoJsonDataFromStorage = (url, type, id, time) => async (dispatch, getState) => {
    let geoData
    const state = getState ();
    let geoJSONData = state.timeGeoJSON.data
    const date = getFileName (url)
    const yearOnDate = findYearInObject(geoJSONData[type], date)
    if (yearOnDate) {
        const filteredData = geoJSONData[type][date]
        if (filteredData !== undefined || typeof filteredData !== 'undefined') {
            geoData = filteredData.data
        } else {
            geoData = await getZippedData (url)
            Object.assign(geoJSONData[type], {[date]: {
                    'id': id,
                    'data': geoData
                }});
            dispatch(setGeoData(geoJSONData));
        }
    } else {
        geoData = await getZippedData (url)
        Object.assign(geoJSONData[type], {[date]: {
            'id': id,
            'data': geoData
        }});
        dispatch(setGeoData(geoJSONData));
    }
    return geoData
};
