import React, { Component } from "react";
import './style.css';

class ErrorMsg extends Component {
    render() {
        return (
                <div className="error-msg">
                    <p>{this.props.text}</p>
                </div>
        );
    }
}

export default ErrorMsg;
