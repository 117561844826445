import { SET_USER } from "../constants";

const getFromStorage = (key) => {
  try {
    const userdata = localStorage.getItem(key);
    if (userdata !== undefined) {
      return JSON.parse(localStorage.getItem("userInfo")).data;
    }
  } catch (e) {
    return null;
  }
};

const initialState = {
  data: null,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};
